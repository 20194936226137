.dropzone {
  height: 200px;
  border: 1px dashed #000;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.custom-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.contact_section {
  padding: 20px;
  color: white;
  z-index: 9999;
  @media (max-width: 1065.98px) {
    padding: 10px 20px;
  }
  @media (max-width: 767.98px) {
    padding: 20px;
  }
  .help_texts {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: end;
  }
  .contact_texts {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    text-align: right;
    @media (max-width: 1252.98px) {
      font-size: 15px;
    }
    a {
      text-decoration: none !important;
    }
  }
  .call_btn {
    background: #0155b7;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 0;
    top: -5px;
    min-width: unset;
  }
}

.css-heg063-MuiTabs-flexContainer {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
  display: none;
}

#custom-pagination {
  .Mui-selected {
    border: 1px solid rgba(21, 190, 83, 1);
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .MuiPaginationItem-previousNext,
  .MuiPaginationItem-firstLast {
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1) !important;
    color: rgba(21, 190, 83, 1);
  }

  .Mui-disabled {
    color: rgba(188, 197, 204, 1);
  }
}

.property-view-details-modal {
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  position: relative;

  .MuiTypography-root {
    color: #0d120b;
  }

  .heading-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
  }

  .label-text {
    .MuiTypography-root {
      word-break: break-word;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .value-text {
    .MuiTypography-root {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }

  .order-document-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: #15be53;
    box-shadow: none;
    border-radius: 20px;
    padding: 6px 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #ffffff;
    z-index: 999;
    margin-right: 10px;
  }

  .order-document-btn:hover {
    background: #15be53;
    outline: none;
  }

  .modal-map {
    height: 400px;
  }
}

.popup_icon {
  height: 85px;
}

.popup_Btn {
  flex-direction: row;
  align-items: flex-start !important;
  padding: 12px 35px !important;
  font-weight: 600 !important;
  gap: 10px;
  border-radius: 29px !important;
  color: #ffffff !important;
  border: none !important;

  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
}
.renew_Btn {
  flex-direction: row;
  align-items: flex-start !important;
  font-weight: 600 !important;
  gap: 10px;
  border-radius: 29px !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 12px !important;
  line-height: 17px !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
}

.success_popup_btn {
  background: #15be53 !important;
}

.error_popup_btn {
  background-color: #ff006b !important;
}

.other_popup_btn {
  background-color: #ffffff !important;
  color: black !important;
  border: 1px #9cb8a6 solid !important;
}
.recording_refund_note {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 25px;
}

.success-btn {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height, or 125% */
  background-color: #15be53 !important;
  border-radius: 29px !important;
  letter-spacing: 0.05em !important;
  padding: 15px 40px;
  text-transform: uppercase;

  color: #ffffff;
}

.cursor-disable {
  cursor: no-drop !important;
}

.cropper-box {
  position: relative;
  width: 500px;
  height: 300px;
}

.cropped-image {
  width: 400px;
}

.modal-close-btn {
  position: absolute !important;
  right: 10px;
  top: 15px;
  background: transparent;
}

.professional_name {
  color: #0d120b;
  font-weight: 400;
  font-size: 14px;
  font-family: IBM Plex Sans;
  line-height: 28px;
}

.professional_role {
  color: #4c524a;
  font-size: 14px;
  font-family: IBM Plex Sans;
  // line-height: 28px;
  text-transform: uppercase;
}

.professional_email {
  color: #15be53 !important;
  text-decoration: none !important;
  // font-size: 14px;
  // font-family: IBM Plex Sans;
  // line-height: 28px;
}

.professional_phone {
  color: #15be53 !important;
  font-size: 14px;
  text-decoration: none !important;
  font-family: IBM Plex Sans;
  // line-height: 28px;
}

.subscription-history-modal {
  .mobile-table {
    .help-contact {
      .supportContact {
        li {
          .label {
            flex: 1 1 auto;
            width: 45%;

            span {
              font-weight: 500 !important;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 28px;
              color: #0d120b;
            }
          }

          .content {
            flex: 1 1 auto;
            width: 50%;
          }
        }
      }
    }
  }
}

.modal-width {
  // max-height: calc(100% - 50px);
  overflow: hidden;
  overflow-y: auto;
}

@media only screen and (max-width: 991.98px) {
  .property-view-details-modal {
    .order-document-btn {
      width: 170px;
    }

    .modal-map {
      height: 318px;
    }
  }
}

@media only screen and (max-width: 768.98px) {
  .property-view-details-modal,
  .view-edit-page {
    .MuiDialogContent-root {
      padding: 0px !important;
    }

    fieldset {
      border: 1px solid #d7dfe7;
      outline: none;
    }

    .mobile-select-tabs {
      background-color: #fff;
      color: #0395ff;
      outline: none;
    }

    .MuiSelect-icon {
      color: #0395ff;
    }
  }

  .Mui-selected {
    color: #0d120b;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .cropper-box {
    width: 400px;
    height: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .property-view-details-modal {
    .MuiDialogTitle-root {
      font-size: 14px;
    }

    .label-text {
      .MuiTypography-root {
        font-size: 12px;
        margin-bottom: 6px;
      }
    }

    .value-text {
      .MuiTypography-root {
        font-size: 12px;
        margin-bottom: 6px;
      }
    }
  }

  .cropper-box {
    width: 300px;
    height: 200px;
  }

  .cropped-image {
    width: 300px;
  }
}

@media only screen and (max-width: 475px) {
  .alert-modal-btn {
    .MuiButtonBase-root {
      padding: 8px 22px !important;
      font-size: 13px !important;
    }
  }
}

@media only screen and (max-width: 400px) {
  .cropper-box {
    width: 250px;
    height: 220px;
  }

  .cropped-image {
    width: 250px;
  }

  .modal-close-btn {
    right: -10px;
    top: 0px;
  }

  .popup_Btn {
    padding: 8px 22px !important;
  }
}

#__new-tabs-container {
  .MuiButtonBase-root.MuiTab-root {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #d7dfe7;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #d7dfe7;
  }
}

.zoom-modal-wrapper {
  .MuiDialog-paper {
    &.MuiDialog-paperWidthLg {
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.tab_component {
  .tab_content {
    padding-left: 40px;
    .tab_header {
      font-family: IBM Plex Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0%;
      text-transform: capitalize;
      color: #0d120b;
    }
    .Mui-selected {
      color: #0155b7;
    }
  }
  .tab_texts {
    padding: 15px 15px 15px 15px;
    font-family: IBM Plex Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #0d120b;
  }
}
